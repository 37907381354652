<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <!--begin::header notification-->
    <b-alert
      :show="showNotification()"
      dismissible
      fade
      variant="danger"
      class="text-center"
      style="background-color:#eeb954; border-color:#eeb954;"
      >{{ notificationMessage }}</b-alert
    >
    <!--end::header notification-->

    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <div class="d-lg-flex flex-row-fluid">
                <!-- begin:: Aside Left -->
                <KTAside v-if="asideEnabled"></KTAside>
                <!-- end:: Aside Left -->

                <div class="content-wrapper flex-row-fluid">
                  <v-app>
                    <transition name="fade-in-up">
                      <router-view />
                    </transition>
                  </v-app>
                </div>
              </div>
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import Vue from "vue";

import { mapGetters } from "vuex";
import KTAside from "@/layouts/aside/Aside.vue";
import KTHeader from "@/layouts/header/Header.vue";
import KTHeaderMobile from "@/layouts/header/HeaderMobile.vue";
import KTFooter from "@/layouts/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/layouts/subheader/Subheader.vue";
// import KTStickyToolbar from "@/layouts/extras/StickyToolbar.vue";
import KTScrollTop from "@/layouts/extras/ScrollTop";
import Loader from "@/views/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

const ignoreWarnMessage = `The .native modifier for v-on is only valid on components but it was used on <div>.`;
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,

    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    // KTStickyToolbar,
    KTScrollTop,
    Loader,
  },
  data() {
    return {
      notificationMessage: "",
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "getSiteNotification",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
  methods: {
    showNotification() {
      if (
        (this.$router.currentRoute.name === "performance" ||
          this.$router.currentRoute.name === "reports") &&
        this.getSiteNotification
      ) {
        this.notificationMessage = this.getSiteNotification;
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
